import { withOidcSecure } from '@axa-fr/react-oidc';
import { Navigate, Route, Routes } from 'react-router';

import { Frame } from './Frame';
import { NotFound } from './NotFound';
import { Request, Requests } from './requests';
import { Teams } from './teams/Teams';
import { Unauthorized } from './Unauthorized';

const SecureFrame = withOidcSecure(Frame);

export const AppRoutes = () => {
    return (
        <Routes>
            <Route Component={SecureFrame} path="/">
                <Route element={<Navigate to={'/requests'} />} path="/" />
                <Route Component={Requests} path="requests">
                    <Route Component={Request} path=":requestId" />
                </Route>
                <Route Component={Teams} path="teams" />
            </Route>
            <Route Component={Unauthorized} path="/unauthorized" />
            <Route Component={NotFound} path="*" />
        </Routes>
    );
};
