import { useOidcUser } from '@axa-fr/react-oidc';
import { ActionIcon, Button, FileButton, InputBase, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useQueryClient } from '@tanstack/react-query';
import { getExceptionDetails, toastNotifications, variants, sendTrackingEvent } from '@uag/react-core';
import { clsx } from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AttachmentType, OnboardingRequestDetailModel } from 'api/models';
import { useUploadAttachmentContent } from 'api/queries/attachments/attachments';
import {
    getGetRequestByIdQueryKey,
    getGetRequestDetailQueryKey,
    getGetRequestsQueryKey,
    useSaveRequestDraft,
    useSubmitRequest
} from 'api/queries/requests/requests';
import { getGetStepsQueryKey } from 'api/queries/steps/steps';
import { TrackingEvents } from 'utils/trackingsEvents';
import { CompanyBaseData } from './CompanyBaseData';
import { companyToRequest, getInitialRequestValues } from './companyFormMappings';
import { requestSchema, RequestSchemaType } from './companyFormSchema';
import { CountryRule, FieldConfigurations } from './CountryRule';

type Props = {
    request: OnboardingRequestDetailModel;
    className?: string;
    readOnly?: boolean;
    mode?: 'Frontend' | 'Backoffice' | 'Invitation';
    onClose: () => void;
};

const clearInvisibleFields = (values: RequestSchemaType) => {
    const countryRule = CountryRule.FindById(values.companyData.country);

    if (!countryRule) {
        return;
    }

    Object.keys(values.companyData).forEach((key) => {
        if (countryRule.fieldConfigurations[key as keyof FieldConfigurations]?.visible === false) {
            (values.companyData as Record<string, unknown>)[key] = undefined;
        }
        if (values.companyData[key as keyof typeof values.companyData] === '') {
            (values.companyData as Record<string, unknown>)[key] = undefined;
        }
    });
};

export const CompanyForm = ({ request, className, onClose, readOnly = false, mode = 'Frontend' }: Props) => {
    const requestId = request.id;

    const { t } = useTranslation();
    const { oidcUser } = useOidcUser();

    const initialValues = getInitialRequestValues(request, oidcUser?.email?.toString().split(',')[0]);

    const form = useForm<RequestSchemaType>({
        mode: 'controlled',
        validate: zodResolver(requestSchema),
        validateInputOnChange: true,
        initialValues
    });

    const {
        getInputProps,
        onSubmit: onSubmitForm,
        setValues,
        setFieldValue,
        isDirty,
        isValid,
        resetDirty,
        getValues
    } = form;

    const queryClient = useQueryClient();
    const [previousRequest, setPreviousRequest] = useState(request);
    const [file, setFile] = useState<File | null>(null);

    const { mutateAsync: saveRequestDraft, isPending: isSaveRequestDraftLoading } = useSaveRequestDraft();
    const { mutateAsync: submitRequest, isPending: isSubmitRequestLoading } = useSubmitRequest();
    const { mutateAsync: uploadAttachmentContent, isPending: isUploadAttachmentContentLoading } =
        useUploadAttachmentContent();

    const isLoading = isSaveRequestDraftLoading || isSubmitRequestLoading || isUploadAttachmentContentLoading;

    const countryRule = CountryRule.FindById(form.values.companyData.country);

    if (request !== previousRequest) {
        setValues(getInitialRequestValues(request));
        resetDirty();
        setPreviousRequest(request);
    }

    const handleOnFileChange = (file: File | null) => {
        setFile(file);
        setFieldValue('businessLicence', file?.name);
    };

    const clearFile = () => {
        setFile(null);
        setFieldValue('businessLicence', undefined);
    };

    const handleSubmit = async (values: RequestSchemaType) => {
        sendTrackingEvent(TrackingEvents.RequestSent);
        clearInvisibleFields(values);

        try {
            if (file) {
                await uploadFile(file);
            }
            await submitRequest({
                id: request.id,
                data: companyToRequest(values)
            });

            resetDirty();
            queryClient.invalidateQueries({ queryKey: getGetRequestDetailQueryKey(requestId) });
            queryClient.invalidateQueries({ queryKey: getGetRequestByIdQueryKey(requestId) });
            queryClient.invalidateQueries({ queryKey: getGetStepsQueryKey(requestId) });
            queryClient.invalidateQueries({ queryKey: getGetRequestsQueryKey() });
            toastNotifications.sucess({ title: t('success'), message: t('dataSaved', { data: t('company') }) });
            onClose();
        } catch (e) {
            toastNotifications.error({
                title: t('failed'),
                message: t('dataNotSaved', { data: t('company'), reason: getExceptionDetails(e)?.message })
            });
        }
    };

    const handleDiscardChanges = async () => {
        setValues(getInitialRequestValues(request));
        resetDirty();
        onClose();
    };

    const handleSaveDraft = async (values: RequestSchemaType) => {
        sendTrackingEvent(TrackingEvents.DraftSaved);

        clearInvisibleFields(values);

        try {
            if (file) {
                await uploadFile(file);
            }
            await saveRequestDraft({
                id: requestId,
                data: companyToRequest(values)
            });
            resetDirty();
            queryClient.invalidateQueries({ queryKey: getGetRequestDetailQueryKey(requestId) });
            queryClient.invalidateQueries({ queryKey: getGetStepsQueryKey(requestId) });
            toastNotifications.sucess({ title: t('success'), message: t('dataSaved', { data: t('company') }) });
            onClose();
        } catch (e) {
            toastNotifications.error({
                title: t('failed'),
                message: t('dataNotSaved', { data: t('company'), reason: getExceptionDetails(e)?.message })
            });
        }
    };

    const uploadFile = async (file: File) => {
        const attachmentId = request?.attachments?.find(
            (attachment) => attachment.attachmentType === AttachmentType.BusinessLicense
        )?.id;

        if (attachmentId) {
            await uploadAttachmentContent({
                id: requestId,
                attachmentId,
                data: {
                    File: file
                }
            });
        }
    };

    const { businessLicence } = getValues();

    return (
        <form className={clsx(className, 'flex w-full flex-col gap-4')} onSubmit={onSubmitForm(handleSubmit)}>
            <CompanyBaseData form={form} readOnly={readOnly} />

            <h4 className="text-base-bold -mb-2 mt-6">{t('keyUser')}</h4>
            <>
                <TextInput
                    description={t('technicalAdministratorDescription')}
                    label={t('technicalAdministrator')}
                    placeholder={t('enterPlaceholder', { label: t('technicalAdministrator') })}
                    readOnly={readOnly}
                    required
                    {...form.getInputProps('technicalAdministrator')}
                />
                <TextInput
                    description={t('contractSignatoryDescription')}
                    label={t('contractSignatory')}
                    placeholder={t('enterPlaceholder', { label: t('contractSignatory') })}
                    readOnly={readOnly}
                    required
                    {...form.getInputProps('contractSignatory')}
                />
            </>
            {mode === 'Frontend' && countryRule?.fieldConfigurations.businessLicenseUpload.visible && (
                <>
                    <h4 className="text-base-bold -mb-2 mt-6">{t('documents')}</h4>
                    <InputBase
                        description={t('businessLicenceDescription')}
                        label={t('businessLicence')}
                        renderRoot={() => {
                            return (
                                <div className="image-picker mt-1 w-full">
                                    {!file && !businessLicence && (
                                        <FileButton
                                            accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*"
                                            onChange={handleOnFileChange}
                                        >
                                            {(props) => (
                                                <Button className="image-picker-button" {...props}>
                                                    {t('uploadDocument')}
                                                </Button>
                                            )}
                                        </FileButton>
                                    )}
                                    {businessLicence && (
                                        <div>
                                            <span className="text-base-bold">{businessLicence}</span>
                                            <ActionIcon className="image-picker-clear-button" onClick={clearFile} />
                                        </div>
                                    )}
                                </div>
                            );
                        }}
                        required={countryRule?.fieldConfigurations.businessLicenseUpload.mandatory}
                        {...getInputProps('businessLicence')}
                    />
                </>
            )}
            {/* {Object.keys(form.errors).length > 0 && (
                <div className="mt-4 text-red-500">
                    {Object.keys(form.errors).map((key) => (
                        <div key={key}>{form.errors[key]}</div>
                    ))}
                </div>
            )} */}

            <div className="modal-action-section mt-2">
                {mode === 'Frontend' && (
                    <>
                        <Button
                            disabled={!isDirty()}
                            loading={isLoading}
                            variant={variants.button.secondary}
                            onClick={() => handleSaveDraft(getValues())}
                        >
                            {t('saveDraft')}
                        </Button>
                        <Button disabled={!isValid()} loading={isLoading} type="submit">
                            {t('submit')}
                        </Button>
                    </>
                )}
                {mode === 'Backoffice' && !readOnly && (
                    <>
                        <Button
                            disabled={!isDirty()}
                            loading={isLoading}
                            variant={variants.button.secondary}
                            onClick={handleDiscardChanges}
                        >
                            {t('discardChanges')}
                        </Button>
                        <Button disabled={!isValid() || !isDirty()} loading={isLoading} type="submit">
                            {t('saveChanges')}
                        </Button>
                    </>
                )}
            </div>
        </form>
    );
};
