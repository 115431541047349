/**
 * Generated by orval v7.4.0 🍺
 * Do not edit manually.
 * Doka Customer Onboarding API
 * OpenAPI spec version: 0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  CreateOrUpdateContributorModel,
  GetContributorByIdResponse,
  GetContributorsResponse,
  GetCurrentContributorResponse,
  ProblemDetails
} from '../../models'
import { customMutator } from '../../customMutator';
import type { ErrorType } from '../../customMutator';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Returns a list of contributors
 */
export const getContributors = (
    
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetContributorsResponse>(
      {url: `/contributors`, method: 'GET', signal
    },
      options);
    }
  

export const getGetContributorsQueryKey = () => {
    return [`/contributors`] as const;
    }

    
export const getGetContributorsQueryOptions = <TData = Awaited<ReturnType<typeof getContributors>>, TError = ErrorType<ProblemDetails>>( options?: { query?:Partial<UseQueryOptions<TData, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetContributorsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getContributors>>> = ({ signal }) => getContributors(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<TData, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetContributorsQueryResult = NonNullable<Awaited<ReturnType<typeof getContributors>>>
export type GetContributorsQueryError = ErrorType<ProblemDetails>


export function useGetContributors<TData = Awaited<ReturnType<typeof getContributors>>, TError = ErrorType<ProblemDetails>>(
  options: { query:Partial<UseQueryOptions<TData, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          TData,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetContributors<TData = Awaited<ReturnType<typeof getContributors>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<TData, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          TData,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetContributors<TData = Awaited<ReturnType<typeof getContributors>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<TData, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useGetContributors<TData = Awaited<ReturnType<typeof getContributors>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<TData, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetContributorsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Creates a new contributor
 */
export const createContributor = (
    createOrUpdateContributorModel: CreateOrUpdateContributorModel,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetContributorByIdResponse>(
      {url: `/contributors`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createOrUpdateContributorModel, signal
    },
      options);
    }
  


export const getCreateContributorMutationOptions = <TData = Awaited<ReturnType<typeof createContributor>>, TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateOrUpdateContributorModel}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {
const mutationKey = ['createContributor'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createContributor>>, {data: CreateOrUpdateContributorModel}> = (props) => {
          const {data} = props ?? {};

          return  createContributor(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: CreateOrUpdateContributorModel}, TContext>}

    export type CreateContributorMutationResult = NonNullable<Awaited<ReturnType<typeof createContributor>>>
    export type CreateContributorMutationBody = CreateOrUpdateContributorModel
    export type CreateContributorMutationError = ErrorType<ProblemDetails>

    export const useCreateContributor = <TData = Awaited<ReturnType<typeof createContributor>>, TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateOrUpdateContributorModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        TData,
        TError,
        {data: CreateOrUpdateContributorModel},
        TContext
      > => {

      const mutationOptions = getCreateContributorMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns a contributor by its id
 */
export const getContributorById = (
    id: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetContributorByIdResponse>(
      {url: `/contributors/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetContributorByIdQueryKey = (id: string,) => {
    return [`/contributors/${id}`] as const;
    }

    
export const getGetContributorByIdQueryOptions = <TData = Awaited<ReturnType<typeof getContributorById>>, TError = ErrorType<ProblemDetails>>(id: string, options?: { query?:Partial<UseQueryOptions<TData, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetContributorByIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getContributorById>>> = ({ signal }) => getContributorById(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<TData, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetContributorByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getContributorById>>>
export type GetContributorByIdQueryError = ErrorType<ProblemDetails>


export function useGetContributorById<TData = Awaited<ReturnType<typeof getContributorById>>, TError = ErrorType<ProblemDetails>>(
 id: string, options: { query:Partial<UseQueryOptions<TData, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          TData,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetContributorById<TData = Awaited<ReturnType<typeof getContributorById>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<TData, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          TData,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetContributorById<TData = Awaited<ReturnType<typeof getContributorById>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<TData, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useGetContributorById<TData = Awaited<ReturnType<typeof getContributorById>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<TData, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetContributorByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Updates a contributor
 */
export const updateContributor = (
    id: string,
    createOrUpdateContributorModel: CreateOrUpdateContributorModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<GetContributorByIdResponse>(
      {url: `/contributors/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: createOrUpdateContributorModel
    },
      options);
    }
  


export const getUpdateContributorMutationOptions = <TData = Awaited<ReturnType<typeof updateContributor>>, TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;data: CreateOrUpdateContributorModel}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {
const mutationKey = ['updateContributor'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateContributor>>, {id: string;data: CreateOrUpdateContributorModel}> = (props) => {
          const {id,data} = props ?? {};

          return  updateContributor(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{id: string;data: CreateOrUpdateContributorModel}, TContext>}

    export type UpdateContributorMutationResult = NonNullable<Awaited<ReturnType<typeof updateContributor>>>
    export type UpdateContributorMutationBody = CreateOrUpdateContributorModel
    export type UpdateContributorMutationError = ErrorType<ProblemDetails>

    export const useUpdateContributor = <TData = Awaited<ReturnType<typeof updateContributor>>, TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;data: CreateOrUpdateContributorModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        TData,
        TError,
        {id: string;data: CreateOrUpdateContributorModel},
        TContext
      > => {

      const mutationOptions = getUpdateContributorMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns the current contributor
 */
export const getCurrentContributor = (
    
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetCurrentContributorResponse>(
      {url: `/contributors/me`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCurrentContributorQueryKey = () => {
    return [`/contributors/me`] as const;
    }

    
export const getGetCurrentContributorQueryOptions = <TData = Awaited<ReturnType<typeof getCurrentContributor>>, TError = ErrorType<ProblemDetails>>( options?: { query?:Partial<UseQueryOptions<TData, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCurrentContributorQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrentContributor>>> = ({ signal }) => getCurrentContributor(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<TData, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetCurrentContributorQueryResult = NonNullable<Awaited<ReturnType<typeof getCurrentContributor>>>
export type GetCurrentContributorQueryError = ErrorType<ProblemDetails>


export function useGetCurrentContributor<TData = Awaited<ReturnType<typeof getCurrentContributor>>, TError = ErrorType<ProblemDetails>>(
  options: { query:Partial<UseQueryOptions<TData, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          TData,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetCurrentContributor<TData = Awaited<ReturnType<typeof getCurrentContributor>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<TData, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          TData,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetCurrentContributor<TData = Awaited<ReturnType<typeof getCurrentContributor>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<TData, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useGetCurrentContributor<TData = Awaited<ReturnType<typeof getCurrentContributor>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<TData, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetCurrentContributorQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



