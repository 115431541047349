import { Button, TextInput } from '@mantine/core';
import { TeamModel, TeamMemberModel } from '@shared/api/models';
import { useGetMyTeams, useGetTeamMembers } from '@shared/api/queries/teams/teams';
import { useQueryClient } from '@tanstack/react-query';
import { BaseLayout, Icon, variants, SkeletonTable, Select, Pagination, getPagingParams, DataTable } from '@uag/react-core';
import { useAtomValue } from 'jotai';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { currentContributorAtom } from 'app/atoms';
import { getColumns } from './getColumns';
import { openDeleteModal } from './modals/DeleteModal';
import { openEditModal } from './modals/EditModal';
import { openInviteModal } from './modals/InviteModal';

// eslint-disable-next-line no-duplicate-imports
import type { ItemsPerPageType } from '@uag/react-core';

export const Teams = () => {
    const { id } = useParams<{ id: string }>();
    const currentContributor = useAtomValue(currentContributorAtom);

    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const { data: myTeams, isLoading: isLoadingTeams } = useGetMyTeams();

    const [selectedTeamId, setSelectedTeamId] = useState<string>(id || '');
    const [itemsPerPage, setItemsPerPage] = useState<ItemsPerPageType>(25);
    const [currentPage, setCurrentPage] = useState(1);

    if (myTeams?.data && myTeams.data.length > 0 && !selectedTeamId) {
        setSelectedTeamId(myTeams.data[0].id || '');
    }

    const {
        data: teamMembers,
        isLoading: isLoadingMembers,
        queryKey
    } = useGetTeamMembers(selectedTeamId, { ...getPagingParams(currentPage, itemsPerPage) });

    const totalPages =
        teamMembers && teamMembers.metadata?.paging?.totalCount
            ? Math.ceil(teamMembers.metadata.paging.totalCount / itemsPerPage)
            : 1;

    const isLoading = isLoadingTeams || isLoadingMembers;

    const isTeamManager =
        teamMembers?.data?.length &&
        teamMembers.data.some(
            (member: TeamMemberModel) => member.memberType === 'Owner' && member.id === currentContributor.id
        );

    const handleInviteClick = () => {
        openInviteModal({
            title: t('inviteCollaborators'),
            acceptButtonText: t('sendInvitation'),
            teamId: selectedTeamId
        }).then(() => queryClient.invalidateQueries({ queryKey }));
    };
    const teamOptions = myTeams?.data?.map((team: TeamModel) => ({
        value: team.id,
        label: team.name
    }));
    
    const columns = getColumns();
    const handleEditClick = (member:TeamMemberModel) => {
        openEditModal({
            title: t('editTeamMember'),
            acceptButtonText: t('saveChanges'),
            memberName:member.displayName,
            teamId: selectedTeamId,
            memberType: member.memberType,
            contributorId: member.id
        }).then(() => queryClient.invalidateQueries({ queryKey }));
    };

    const handleDeleteClick = (member:TeamMemberModel) => {
        openDeleteModal({
            title: `${t('removeCollaborator')}?`,
            acceptButtonText: t('confirmRemoval'),
            memberName:member.displayName,
            teamId: selectedTeamId,
            contributorId:member.id
        }).then(() => queryClient.invalidateQueries({ queryKey }));
    };


    return (
        <BaseLayout>
            <div className="flex flex-col gap-base pb-base">
                <span className="text-3xl font-default font-semibold leading-3xl">{t('responsibleTeam')}</span>
                <div className="grid grid-cols-2">
                    {!isLoadingTeams && teamOptions?.length && teamOptions.length > 1 && (
                        <Select
                            className="w-fit justify-start"
                            data={teamOptions}
                            value={selectedTeamId}
                            searchable
                            onChange={(value) => setSelectedTeamId(value ?? '')}
                        />
                    )}
                    <div className="col-start-2 flex justify-end gap-2">
                        <TextInput
                            leftSection={<Icon icon="search" />}
                            placeholder={`${t('search')}`}
                            variant={variants.textField.iconDecoration}
                        />
                        {isTeamManager && (
                            <Button onClick={handleInviteClick}>
                                <span>{t('inviteCollaborators')}</span>
                                <Icon className="ml-base" icon="person_add" />
                            </Button>
                        )}
                    </div>
                </div>
                {isLoading && <SkeletonTable columns={3} rows={5} />}
                {!isLoading && (
                    <>
                        <DataTable
                            columns={columns}
                            data={teamMembers?.data ?? []}
                            withHeader
                        >
                                <DataTable.RowActions>
                                    <DataTable.RowAction
                                        icon="edit"
                                        label="Edit"
                                        onClick={handleEditClick}
                                        />
                                    <DataTable.RowAction
                                        icon="delete"
                                        label="Delete"
                                        onClick={handleDeleteClick}
                                        />
                                    <DataTable.RowActionDivider />
                                </DataTable.RowActions>
                        </DataTable>
                        <Pagination
                            className="mt-8"
                            itemsPerPage={itemsPerPage}
                            page={currentPage}
                            totalPages={totalPages}
                            onItemsPerPageChange={setItemsPerPage}
                            onPageChange={setCurrentPage}
                        />
                    </>
                )}
            </div>
        </BaseLayout>
    );
};
