/**
 * Generated by orval v7.4.0 🍺
 * Do not edit manually.
 * Doka Customer Onboarding API
 * OpenAPI spec version: 0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AddTeamMemberModel,
  CreateOrUpdateTeamModel,
  GetMyTeamsResponse,
  GetTeamByCountryAndZipCodeParams,
  GetTeamByCountryAndZipCodeResponse,
  GetTeamByIdResponse,
  GetTeamMembersParams,
  GetTeamMembersResponse,
  GetTeamsResponse,
  ProblemDetails
} from '../../models'
import { customMutator } from '../../customMutator';
import type { ErrorType } from '../../customMutator';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Returns a list of teams
 */
export const getTeams = (
    
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetTeamsResponse>(
      {url: `/teams`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTeamsQueryKey = () => {
    return [`/teams`] as const;
    }

    
export const getGetTeamsQueryOptions = <TData = Awaited<ReturnType<typeof getTeams>>, TError = ErrorType<ProblemDetails>>( options?: { query?:Partial<UseQueryOptions<TData, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTeamsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTeams>>> = ({ signal }) => getTeams(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<TData, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetTeamsQueryResult = NonNullable<Awaited<ReturnType<typeof getTeams>>>
export type GetTeamsQueryError = ErrorType<ProblemDetails>


export function useGetTeams<TData = Awaited<ReturnType<typeof getTeams>>, TError = ErrorType<ProblemDetails>>(
  options: { query:Partial<UseQueryOptions<TData, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          TData,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetTeams<TData = Awaited<ReturnType<typeof getTeams>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<TData, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          TData,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetTeams<TData = Awaited<ReturnType<typeof getTeams>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<TData, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useGetTeams<TData = Awaited<ReturnType<typeof getTeams>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<TData, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetTeamsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Creates a new team
 */
export const createTeam = (
    createOrUpdateTeamModel: CreateOrUpdateTeamModel,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetTeamByIdResponse>(
      {url: `/teams`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createOrUpdateTeamModel, signal
    },
      options);
    }
  


export const getCreateTeamMutationOptions = <TData = Awaited<ReturnType<typeof createTeam>>, TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateOrUpdateTeamModel}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {
const mutationKey = ['createTeam'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTeam>>, {data: CreateOrUpdateTeamModel}> = (props) => {
          const {data} = props ?? {};

          return  createTeam(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: CreateOrUpdateTeamModel}, TContext>}

    export type CreateTeamMutationResult = NonNullable<Awaited<ReturnType<typeof createTeam>>>
    export type CreateTeamMutationBody = CreateOrUpdateTeamModel
    export type CreateTeamMutationError = ErrorType<ProblemDetails>

    export const useCreateTeam = <TData = Awaited<ReturnType<typeof createTeam>>, TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateOrUpdateTeamModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        TData,
        TError,
        {data: CreateOrUpdateTeamModel},
        TContext
      > => {

      const mutationOptions = getCreateTeamMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns a team by its id
 */
export const getTeamById = (
    id: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetTeamByIdResponse>(
      {url: `/teams/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTeamByIdQueryKey = (id: string,) => {
    return [`/teams/${id}`] as const;
    }

    
export const getGetTeamByIdQueryOptions = <TData = Awaited<ReturnType<typeof getTeamById>>, TError = ErrorType<ProblemDetails>>(id: string, options?: { query?:Partial<UseQueryOptions<TData, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTeamByIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTeamById>>> = ({ signal }) => getTeamById(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<TData, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetTeamByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getTeamById>>>
export type GetTeamByIdQueryError = ErrorType<ProblemDetails>


export function useGetTeamById<TData = Awaited<ReturnType<typeof getTeamById>>, TError = ErrorType<ProblemDetails>>(
 id: string, options: { query:Partial<UseQueryOptions<TData, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          TData,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetTeamById<TData = Awaited<ReturnType<typeof getTeamById>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<TData, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          TData,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetTeamById<TData = Awaited<ReturnType<typeof getTeamById>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<TData, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useGetTeamById<TData = Awaited<ReturnType<typeof getTeamById>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<TData, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetTeamByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Returns a team by its country id and optional zip code
 */
export const getTeamByCountryAndZipCode = (
    countryConfigurationId: string,
    params?: GetTeamByCountryAndZipCodeParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetTeamByCountryAndZipCodeResponse>(
      {url: `/teams/country/${countryConfigurationId}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTeamByCountryAndZipCodeQueryKey = (countryConfigurationId: string,
    params?: GetTeamByCountryAndZipCodeParams,) => {
    return [`/teams/country/${countryConfigurationId}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTeamByCountryAndZipCodeQueryOptions = <TData = Awaited<ReturnType<typeof getTeamByCountryAndZipCode>>, TError = ErrorType<ProblemDetails>>(countryConfigurationId: string,
    params?: GetTeamByCountryAndZipCodeParams, options?: { query?:Partial<UseQueryOptions<TData, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTeamByCountryAndZipCodeQueryKey(countryConfigurationId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTeamByCountryAndZipCode>>> = ({ signal }) => getTeamByCountryAndZipCode(countryConfigurationId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(countryConfigurationId), ...queryOptions} as UseQueryOptions<TData, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetTeamByCountryAndZipCodeQueryResult = NonNullable<Awaited<ReturnType<typeof getTeamByCountryAndZipCode>>>
export type GetTeamByCountryAndZipCodeQueryError = ErrorType<ProblemDetails>


export function useGetTeamByCountryAndZipCode<TData = Awaited<ReturnType<typeof getTeamByCountryAndZipCode>>, TError = ErrorType<ProblemDetails>>(
 countryConfigurationId: string,
    params: undefined |  GetTeamByCountryAndZipCodeParams, options: { query:Partial<UseQueryOptions<TData, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          TData,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetTeamByCountryAndZipCode<TData = Awaited<ReturnType<typeof getTeamByCountryAndZipCode>>, TError = ErrorType<ProblemDetails>>(
 countryConfigurationId: string,
    params?: GetTeamByCountryAndZipCodeParams, options?: { query?:Partial<UseQueryOptions<TData, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          TData,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetTeamByCountryAndZipCode<TData = Awaited<ReturnType<typeof getTeamByCountryAndZipCode>>, TError = ErrorType<ProblemDetails>>(
 countryConfigurationId: string,
    params?: GetTeamByCountryAndZipCodeParams, options?: { query?:Partial<UseQueryOptions<TData, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useGetTeamByCountryAndZipCode<TData = Awaited<ReturnType<typeof getTeamByCountryAndZipCode>>, TError = ErrorType<ProblemDetails>>(
 countryConfigurationId: string,
    params?: GetTeamByCountryAndZipCodeParams, options?: { query?:Partial<UseQueryOptions<TData, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetTeamByCountryAndZipCodeQueryOptions(countryConfigurationId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Adds a contributor to a team
 */
export const addTeamMember = (
    id: string,
    addTeamMemberModel: AddTeamMemberModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/teams/${id}/members`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: addTeamMemberModel
    },
      options);
    }
  


export const getAddTeamMemberMutationOptions = <TData = Awaited<ReturnType<typeof addTeamMember>>, TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;data: AddTeamMemberModel}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {
const mutationKey = ['addTeamMember'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addTeamMember>>, {id: string;data: AddTeamMemberModel}> = (props) => {
          const {id,data} = props ?? {};

          return  addTeamMember(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{id: string;data: AddTeamMemberModel}, TContext>}

    export type AddTeamMemberMutationResult = NonNullable<Awaited<ReturnType<typeof addTeamMember>>>
    export type AddTeamMemberMutationBody = AddTeamMemberModel
    export type AddTeamMemberMutationError = ErrorType<ProblemDetails>

    export const useAddTeamMember = <TData = Awaited<ReturnType<typeof addTeamMember>>, TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;data: AddTeamMemberModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        TData,
        TError,
        {id: string;data: AddTeamMemberModel},
        TContext
      > => {

      const mutationOptions = getAddTeamMemberMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get all team members
 */
export const getTeamMembers = (
    id: string,
    params?: GetTeamMembersParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetTeamMembersResponse>(
      {url: `/teams/${id}/members`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTeamMembersQueryKey = (id: string,
    params?: GetTeamMembersParams,) => {
    return [`/teams/${id}/members`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTeamMembersQueryOptions = <TData = Awaited<ReturnType<typeof getTeamMembers>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetTeamMembersParams, options?: { query?:Partial<UseQueryOptions<TData, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTeamMembersQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTeamMembers>>> = ({ signal }) => getTeamMembers(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<TData, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetTeamMembersQueryResult = NonNullable<Awaited<ReturnType<typeof getTeamMembers>>>
export type GetTeamMembersQueryError = ErrorType<ProblemDetails>


export function useGetTeamMembers<TData = Awaited<ReturnType<typeof getTeamMembers>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params: undefined |  GetTeamMembersParams, options: { query:Partial<UseQueryOptions<TData, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          TData,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetTeamMembers<TData = Awaited<ReturnType<typeof getTeamMembers>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetTeamMembersParams, options?: { query?:Partial<UseQueryOptions<TData, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          TData,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetTeamMembers<TData = Awaited<ReturnType<typeof getTeamMembers>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetTeamMembersParams, options?: { query?:Partial<UseQueryOptions<TData, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useGetTeamMembers<TData = Awaited<ReturnType<typeof getTeamMembers>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetTeamMembersParams, options?: { query?:Partial<UseQueryOptions<TData, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetTeamMembersQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Removes a contributor from a team
 */
export const removeTeamMember = (
    id: string,
    contributorId: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/teams/${id}/contributor/${contributorId}`, method: 'DELETE'
    },
      options);
    }
  


export const getRemoveTeamMemberMutationOptions = <TData = Awaited<ReturnType<typeof removeTeamMember>>, TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;contributorId: string}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {
const mutationKey = ['removeTeamMember'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeTeamMember>>, {id: string;contributorId: string}> = (props) => {
          const {id,contributorId} = props ?? {};

          return  removeTeamMember(id,contributorId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{id: string;contributorId: string}, TContext>}

    export type RemoveTeamMemberMutationResult = NonNullable<Awaited<ReturnType<typeof removeTeamMember>>>
    
    export type RemoveTeamMemberMutationError = ErrorType<ProblemDetails>

    export const useRemoveTeamMember = <TData = Awaited<ReturnType<typeof removeTeamMember>>, TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;contributorId: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        TData,
        TError,
        {id: string;contributorId: string},
        TContext
      > => {

      const mutationOptions = getRemoveTeamMemberMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get all teams for the current user
 */
export const getMyTeams = (
    
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetMyTeamsResponse>(
      {url: `/teams/my`, method: 'GET', signal
    },
      options);
    }
  

export const getGetMyTeamsQueryKey = () => {
    return [`/teams/my`] as const;
    }

    
export const getGetMyTeamsQueryOptions = <TData = Awaited<ReturnType<typeof getMyTeams>>, TError = ErrorType<ProblemDetails>>( options?: { query?:Partial<UseQueryOptions<TData, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMyTeamsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMyTeams>>> = ({ signal }) => getMyTeams(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<TData, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetMyTeamsQueryResult = NonNullable<Awaited<ReturnType<typeof getMyTeams>>>
export type GetMyTeamsQueryError = ErrorType<ProblemDetails>


export function useGetMyTeams<TData = Awaited<ReturnType<typeof getMyTeams>>, TError = ErrorType<ProblemDetails>>(
  options: { query:Partial<UseQueryOptions<TData, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          TData,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetMyTeams<TData = Awaited<ReturnType<typeof getMyTeams>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<TData, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          TData,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetMyTeams<TData = Awaited<ReturnType<typeof getMyTeams>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<TData, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useGetMyTeams<TData = Awaited<ReturnType<typeof getMyTeams>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<TData, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetMyTeamsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



